<template>
	<div id="box_mod">
		<div class="box_mod_nav">
			<img src="@/assets/images/Vs/box_text.png" alt="">
			<div class="tit">
				<van-icon name="question-o" color="#fff" size="20"/>
				<p @click="G_Show = true">游戏规则</p>
			</div>
		</div>
		<div class="box_mod_list">
			<div class="box_mod_item" v-for="(item,index) in ArenaBoxList" :key="index" @click="GetArenaArr(item)">
				<div class="vox_mod_pic">
					<img :src="item.cover" alt="">
				</div>
				<p>{{item.name}}</p>
				<span><img src="@/assets/images/public/Gold.png" alt="">{{item.game_bean}}</span>
			</div>
		</div>
		<!-- 规则遮罩层 -->
		<van-overlay :show="G_Show">
			<div class="wrapper">
				<div class="LogOut_box">
					<div class="close" @click="G_Show = false"><img src="@/assets/images/public/close.png" alt=""></div>
					<div class="tit">对战规则</div>
					<p style="color: #fff; font-size: 16px;">1 创建大乱斗时，可以选择乱斗人数（1V1，3人对战和4人对战），一场乱斗最多可以选择 6 个盲盒。房间一但创建，不可取消。<br/>
						2 乱斗入场费用即本场乱斗开启的盲盒价格总和，支付费用后，即视为加入房间，中途退出房间，并不会退还盲盒或入场费。<br/>
						3 当一场乱斗有多名赢家均分奖励时，非本人所得饰品将被兑换为硬币发放到赢家的账户余额。<br/>
						4 每场游戏的结果受到（创建时间，玩家ID，玩家位置和开始时间等）多方面因素影响，为完全随机产生的结果。任意盲盒奖励均可以验证公平性。<br/>
						声明：985csgo将根据实际运营情况保留随时调整返佣比例的权利，以上活动最终解释权归985csgo所有!
					</p>
				</div>
			</div>
		</van-overlay>
		
		
		<!-- 物品遮罩层 -->
		<van-overlay :show="Show" :lock-scroll="false">
			<div class="wrapper">
				<div class="LogOut_box box_mod_out">
					<div class="close" @click="Show = false"><img src="@/assets/images/public/close.png" alt=""></div>
					<div class="box_mod_top">
						<img :src="ArenaImg" alt="">
						<p>{{ArenaTitle}}</p>
					</div>
					<div class="Explosive">
					<p><img src="../../../../assets/images/open/yellow.png" alt="">:{{yellow.toFixed(2)}}%</p>
					<p><img src="../../../../assets/images/open/red.png" alt="">:{{red.toFixed(2)}}%</p>
					<p><img src="../../../../assets/images/open/prople.png" alt="">:{{purple.toFixed(2)}}%</p>
					<p><img src="../../../../assets/images/open/blue.png" alt="">:{{blue.toFixed(2)}}%</p>
					<p><img src="../../../../assets/images/open/hui.png" alt="">:{{gray.toFixed(2)}}%</p>
				</div>
					<div class="mod_boxlist">
						<div class="mod_boxitem" v-for="(item,index) in ArenaArr" :key="index">
							<div class="pktop">
								<!-- <div class="bao">概率：{{item.odds_percent}}</div> -->
								<p><img src="@/assets/images/PublicImg/JinBi.png" style="width:15px" alt="">{{ item.bean }}</p>
							</div>
							<div class="mod_boxpic" :style="{'background-image': 'url('+item.lv_bg_image+')'}">
								<img :src="item.cover" alt="">
							</div>
							<p>{{item.name}}</p>
							<!-- <span><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</span> -->
						</div>
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import { ArenaBoxList } from '@/network/api.js'
	export default{
		name:'box_mod',
		data(){
			return{
				G_Show:false,//规则弹框
				ArenaBoxList:[],//宝箱列表
				Show:false,//物品弹框
				ArenaTitle:'',//弹框标题
				ArenaImg:'',//弹框图片
				ArenaArr:[],//箱内物品列表
				yellow:0,
				red:0,
				purple:0,
				blue:0,
				gray:0,

			}
		},
		created() {
			this.GetArenaBoxList()
		},
		methods:{
			//宝箱列表
			GetArenaBoxList(){
				ArenaBoxList().then((res) => {
					this.ArenaBoxList = res.data.data
					// console.log(this.ArenaBoxList);
					if(this.ArenaBoxList.length>0){
					// this.yellow=0,
					// this.red=0,
					// this.purple=0,
					// this.blue=0,
					// this.gray=0
					// this.ArenaBoxList.forEach((item)=>{
					// 	item.box_awards.forEach(v=>{
					// 		if(v.lv==1){
					// 			this.yellow+=Number(v.odds)
					// 		}
					// 		if(v.lv==2){
					// 			this.red+=Number(v.odds)
					// 		}
					// 		if(v.lv==3){
					// 			this.purple+=Number(v.odds)
					// 		}
					// 		if(v.lv==4){
					// 			this.blue+=Number(v.odds)
					// 		}
					// 		if(v.lv==5){
					// 			this.gray+=Number(v.odds)
					// 		}
					// 	})
					
					// })
				}
				})
			},
			//箱内物品
			GetArenaArr(item){
				this.yellow=0,
				this.red=0,
				this.purple=0,
				this.blue=0,
				this.gray=0
				// console.log(item)
				this.ArenaTitle = item.name
				this.ArenaImg = item.cover
				this.ArenaArr = item.box_awards
				// console.log(this.ArenaArr);
				this.Show = true
				item.box_awards.forEach((item)=>{
					if(item.lv==1){
						this.yellow+=Number(item.odds)
					}
					if(item.lv==2){
						this.red+=Number(item.odds)
					}
					if(item.lv==3){
						this.purple+=Number(item.odds)
					}
					if(item.lv==4){
						this.blue+=Number(item.odds)
					}
					if(item.lv==5){
						this.gray+=Number(item.odds)
					}
				})
			}
		}
	}
</script>

<style lang="scss">
.Explosive{
				width: 100%;
				display: flex;
				justify-content: space-around;
				align-items: center;
				background: url(../../../../assets/images/open/Explosive.png) no-repeat center;
				background-size: 100% 100%;
				height: 30px;
				color: white;
				width: 98%;
				margin: 5px auto;
				img{
					width: 10px;
				}
			}
	#box_mod{
		width: 100%;
		.box_mod_nav{
			width: 100%;
			height: 16px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			img{
				height: 100%;
			}
			.tit{
				height: 100%;
				display: flex;
				align-items: center;
				p{
					font-size: 14px;
					color: #fff;
					margin-left: 2px;
				}
			}
		}
		.box_mod_list::-webkit-scrollbar {
			width: 4px;
		}
		.box_mod_list::-webkit-scrollbar-thumb {
			border-radius: 10px;
			box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
			background: rgba(255, 255, 255, 0.2);
		}
		.box_mod_list::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
			border-radius: 0;
			background: rgba(255, 255, 255, 0.1);
		}
		.box_mod_list{
			width: 100%;
			margin-top: 10px;
			background: rgba($color: #000000, $alpha: .2);
			border-radius: 6px;
			overflow: hidden;
			overflow-x: auto;
			white-space: nowrap;
			padding: 5px;
			box-sizing: border-box;
			
			.box_mod_item{
				width: 80px;
				margin-right: 5px;
				display: inline-block;
				border-radius: 4px;
				margin-right: 5px;
				text-align: center;
				background: rgba($color: #fff, $alpha: .02);
				background: url('../../../../assets/images/985/pk/pkbox.png') no-repeat center;
				background-size: 100% 100%;
				&:last-child{
					margin-right: 0;
				}
				.vox_mod_pic{
					width: 70px;
					height: 60px;
					margin: 0 auto;
					display: flex;
					justify-content: center;
					align-items: center;
					img{
						max-width: 100%;
						max-height: 100%;
						vertical-align: middle;
					}
				}
				p{
					width: 100%;
					padding: 0 5px;
					box-sizing: border-box;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					color: #fff;
					font-size: 12px;
					margin-bottom: 8px;
					margin-top: 8px;
				}
				span{
					font-size: 12px;
					color: #f1bc60;
					display: block;
					img{
						width: 12px;
						height: 12px;
						vertical-align: middle;
						margin-bottom: 2px;
					}
				}
			}
		}
	
		.box_mod_out{
			.box_mod_top{
				width: 100%;
				height: 34px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #11151f;
				img{
					height: 34px;
					vertical-align: middle;
					margin-right: 10px;
				}
				p{
					font-size: 14px;
					color: #fff;
					font-weight: bold;
				}
			}
			.mod_boxlist{
				width: 100%;
				height: 400px;
				background: #20222b;
				border-radius:0 0 6px 6px;
				overflow-y: auto;
				padding:5px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.mod_boxitem{
					display: inline-block;
					width: 48%;
					margin-bottom: 10px;
					background: rgba($color: #fff, $alpha: .02);
					border-radius: 4px;
					text-align: center;
					padding-bottom: 10px;
					background-size: 100% 100%;
						border-radius: 5px;
    					border: 1px solid rgb(210, 73, 73);
						// background: rgb(213, 100, 100);
						background: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(133, 46, 46));
					.bao{
						text-align: left;
						color: #beb3b3;
						white-space:nowrap;
					}
					.pktop{
						display: flex;
						justify-content: space-between;
						flex-wrap: nowrap;
						p{
							width: 100%;
							font-size: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
						}
					}
					.mod_boxpic{
						width: 120px;
						height: 120px;
						margin: 0 auto;
						display: flex;
						align-items: center;
						justify-content: center;
						background-size: 70%;
						background-position: center;
						background-repeat: no-repeat;
						
						img {
							vertical-align: middle;
							max-width: 80%;
							max-height: 80%;
						}
					}
					p{
						font-size: 14px;
						padding: 0 8px;
						text-align: center;
						box-sizing: border-box;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						color: #fff;
					}
					span {
						display: block;
						margin-top: 5px;
						text-align: center;
						font-size: 14px;
						color: #f1bc60;
						margin: 5px 0;
					
						img {
							width: 16px;
							height: 16px;
							vertical-align: middle;
							margin-bottom: 2px;
						}
					}
				}
				
			}
			
		}
	}
</style>
