<template>
	<div id="Battle">
		<boxmod></boxmod>
		<div class="Battle_nav">
			<van-button type="info" size="small" class="van_button_info">我的对战</van-button>
			<van-button size="small" @click="ToBattlehistory">对战历史</van-button>
			<van-button size="small" @click="Show = true">创建对战</van-button>
		</div>
		<div class="box">
			<div :class="{'active' : Status == '1'}" @click="Rankings('1')">全部</div>
			<div :class="{'active' : Status == '2'}" @click="Rankings('2')">1-100</div>
			<div :class="{'active' : Status == '3'}" @click="Rankings('3')">100-500</div>
			<div :class="{'active' : Status == '4'}" @click="Rankings('4')">500+</div>
		</div>
		<div class="Battle_list">
			<transition-group name="list" tag="div">
				<BattleItem v-for="(item,index) in ArenaArr" :id="item.id" :key="item.id"></BattleItem>
			</transition-group>
		</div>
		<el-pagination style="margin-bottom: 10px;" background layout="prev, pager, next" @current-change="handleCurrentChange"
		 :total="total" :page-size="per_page" :pager-count='5' prev-text="上一页" next-text="下一页" small>
		</el-pagination>

		<van-popup v-model="Show" :style="{ width: '100%',height: '100%' }" class="box-back">
			<!-- <div class="Title">
				<p>创建对战</p>
			</div> -->
			<div class="choose">
				<p>已选箱子总额</p><span><img src="@/assets/images/public/Gold.png" alt="">{{TotalPrice.toFixed(2)}}</span>
			</div>
			<div class="pattern">
				<p :class="{'active' : situation == '2'}" @click="situation = '2'">双人模式</p>
				<p :class="{'active' : situation == '3'}" @click="situation = '3'">三人模式</p>
				<!-- <p :class="{'active' : situation == '4'}" @click="situation = '4'">四人模式</p> -->
			</div>

		

			<div class="blind">
				<!-- <div class="blind_tit">选择盲盒</div> -->
				<div class="blind_list">
					<div class="blind_item" v-for="(item,index) in BlindArr" :key="index" ref="Item">
						<div class="Repair">
							<div class="What">
								<van-icon name="cross" color="#fff" size="14" />
								{{item.length}}
							</div>
							<div class="less"><img v-show="item.length > 0" src="@/assets/images/Vs/subtract.png" alt="" @click="SubtractItem(item,index)"></div>
							
						</div>
						<div class="blind_pic">
							<img :src="item.cover" alt="" @click="ChoiceItem(item,index)">
						</div>
						<span><img src="@/assets/images/public/Gold.png" alt="">{{item.game_bean}}</span>
						<p>{{item.name}}</p>
					</div>
				</div>
			</div>

			<div class="batch">
				<div class="batch_tit">批量创建</div>
				<van-radio-group v-model="radio" direction="horizontal">
					<van-radio name="1" icon-size="20px">1个</van-radio>
					<van-radio name="2" icon-size="20px">2个</van-radio>
					<van-radio name="3" icon-size="20px">3个</van-radio>
					<van-radio name="5" icon-size="20px">5个</van-radio>
				</van-radio-group>
			</div>

			<div class="establish_btns">
				<van-button plain type="info" size="small" @click="Cancel">取消创建</van-button>
				<van-button type="info" size="small" @click="establish">确认创建</van-button>
			</div>

		</van-popup>





<div class="phb" >
	<img @click="isShowPHB" src="@/assets/images/Vs/phb.png" alt="">
</div>
	<div class="phb-fied" v-if="isShow">
		<div class="VSbox_list text-white">
			<div class="" style="display: flex;justify-content: end;width: 90%;margin: 0 auto">
				<img @click="isShow = false" src="@/assets/images/Vs/off.png" style="width: 60px" alt="">
			</div >
			<div v-if="getRankingTopWTSList.today.length > 0">
				<div class="VsTop" v-if="flag">
					<img :src="getRankingTopWTSList.today[0].user.avatar" alt="">
					<p>积分：{{getRankingTopWTSList.today[0].expend }}</p>
				</div>
				
				<div class="VsTop" v-else>
					<img :src="getRankingTopWTSList.today[0].user.avatar" alt="">
					<p>赢得：{{getRankingTopWTSList.today[0].win }}</p>
				</div>
			</div>
			
			<div class="Vs-right-bg">
				<div class="vsbox_item" style="width: 100%">
					<div class="vsbox_item_jf " :class="ranKingActive == 1 ?'active':''" @click="getRanking(1)">
						积分排行榜
					</div>
					<div class="vsbox_item_SL" :class="ranKingActive == 2 ?'active':''" @click="getRanking(2)">
						欧皇排行榜
					</div>
				</div>
				<div class="mt-1 ranking-list position-relative">
					<div class="ranking-top">
						<img style="width: 100%" src="@/assets/images/Vs/rank-title-top.png" alt="">
					</div>
					<div>

						<ul class="ranking-list-items" v-if="getRankingTopWTSList.today.length > 0">
							<li class="ranking-list-item d-flex" v-for="(item,index) in getRankingTopWTSList.today" :key="index">
								<div class="rake" :class="`rake-${index+1}`">{{ index+1 }}</div>
								<div class="list-items-user">
									<img :src="item.user.avatar" alt="">
								</div>
								<div class="list-items-name text-truncate">
									{{ item.user.name }}
								</div>
								<div class="list-items-jf">
									{{ ranKingActive==1?'积分':'赢得' }}
									<span class="text-truncate">{{ranKingActive==1? item.expend :item.win }}</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>


	</div>
</template>

<script>
	import boxmod from './components/box_mod/index.vue' //箱子展示模块
	import BattleItem from './components/Battle_item/index.vue' //对战房间展示模块
	import activelist from '../../assets/images/q2/addpk/activelist.png'
	import { Notify } from 'vant';
	import {
		ArenaList,
		ArenaBoxList,
		Arena_Create,
		getRanking,
		winRanking
	} from '@/network/api.js'
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'Battle',
		data() {
			return {
				ArenaArr: [], //对战列表
				total: 0, //数据总数
				per_page: 0, //一页数据
				page: 1, //页码
				Show: false,

				//创建相关
				BlindArr: [], //盲盒列表(处理后)
				ArenaBrr:[],
				situation: '2', //模式选择
				TotalPrice: 0, //选中箱子总价
				choiceList: [], //选中盲盒列表(不显示)
				radio: '1', //批量创建
				ranKingActive: 1,
				// 排行榜列表
				getRankingTopWTSList:{
					yesterday:[{expend:'0.00',user:{name:'暂无',avatar:''}}],
					today:[{expend:'0.00',user:{name:'暂无',avatar:''}}],
				},

				Status:'1',

				isShow:false,

				flag:false

			}
		},
		updated(){
			
		},
		created() {
			this.GetArenaList()
			this.handleArenaBoxList()
			this.getRanking()
		},

		methods: {
			
			Rankings(v){
				this.Status=v
				
				if(v==1){
					this.ArenaArr=this.ArenaBrr
				}else if(v==2){
					this.ArenaArr=this.ArenaBrr
					setTimeout(() => {
						let arr=[]
						this.ArenaArr.forEach((item) => {
							if(item.total_bean<100){
								arr.push(item)
							}
						})
						this.ArenaArr=arr
					}, 500)

				}else if(v==3){
					this.ArenaArr=this.ArenaBrr
					setTimeout(() => {
						let arr=[]
						this.ArenaArr.forEach((item) => {
							if(100<item.total_bean && item.total_bean<500){
								arr.push(item)
							}
						})
						this.ArenaArr=arr
					}, 500)
				}else if(v==4){
					this.ArenaArr=this.ArenaBrr
					setTimeout(() => {
						let arr=[]
						this.ArenaArr.forEach((item) => {
							if(500<item.total_bean){
								arr.push(item)
								// console.log(arr);
							}
						})
						this.ArenaArr=arr
					}, 500)
					
				}
				
			},
			isShowPHB(){
				if(this.getRankingTopWTSList.today[0].user.avatar){
					this.isShow = true
					this.getRanking(this.ranKingActive)
				}else{
					this.$notify({
						type: 'error',
						message: 'VIP5级以上修改'
					})
				}
				
			},
			// 获取排行榜
			async getRanking(type = 1){
				this.ranKingActive = type
				if(type == 1){
					let king = await getRanking()
					this.getRankingTopWTSList = king.data.data
					this.flag=true
				}else {
					let {data} = await winRanking()
					this.getRankingTopWTSList = data.data
					this.flag=false
				}

			},
			//对战列表
			GetArenaList() {
				ArenaList(this.page).then((res) => {
					// console.log(res.data.data.data)
					this.total = res.data.data.total
					this.per_page = res.data.dataper_page
					this.ArenaArr = res.data.data.data
					this.ArenaBrr=res.data.data.data
				})
			},

			//对战列表分页
			handleCurrentChange(v) {
				this.page = v
				this.GetArenaList()
			},

			//路由跳转历史
			ToBattlehistory() {
				this.$router.push('/Battlehistory')
			},

			//创建相关
			//盲盒列表
			handleArenaBoxList() {
				ArenaBoxList().then((res) => {
					//给每一项新增length属性
					for (let i = 0; i < res.data.data.length; i++) {
						let key = "length";
						let value = 0
						res.data.data[i][key] = value
					}
					this.BlindArr = res.data.data
				})
			},

			//增
			ChoiceItem(item, index) {
				if (this.choiceList.length < 6) { //若选中数组length < 6
					this.$refs.Item[index].style['background-image'] = `url(${activelist})`
					this.choiceList.push(item) //添加进选中数组
					item.length += 1 //length + 1
				} else {
					Notify({
						message: '最多选择6个盲盒',
						// type: 'danger',
						color: '#ad0000',
						background: '#ffe1e1',
					});
				}
			},

			//删
			SubtractItem(item, index) {
				//查找第一个符合条件的下标
				let Item_index = this.choiceList.findIndex((v) => {
					return v.id == item.id
				})
				//删除该下标所对应的对象
				this.choiceList.splice(Item_index, 1)
				item.length -= 1 //length-1
				if (item.length == 0) { //若length == 0，去掉选中效果
					this.$refs.Item[index].style = 'border: 1px solid transparent;'
				}
			},

			//创建
			establish() {
				for (let i = 0; i < this.radio; i++) {
					let box = []
					for (let i = 0; i < this.choiceList.length; i++) {
						box.push(this.choiceList[i].id)
					}
					Arena_Create(this.situation, box).then((res) => {
						this.$notify({
							type: 'success',
							message: '创建成功'
						})
						this.Cancel()
					})
				}
			},

			//取消
			Cancel() {
				this.Show = false //关闭侧栏
				this.situation = '2' //对战模式复原
				this.choiceList = [] //选中列表清空
				for (let i = 0; i < this.BlindArr.length; i++) { //箱子length归0
					this.BlindArr[i].length = 0
				}
				for (let k = 0; k < this.$refs.Item.length; k++) { //选中样式清空
					this.$refs.Item[k].style = 'border: 1px solid transparent;'
				}
				this.radio = '1' //批量复原
			}



		},

		computed: {
			...mapState([
				'NewGroup'
			])
		},

		watch: {
			NewGroup(v) { //监听新房间变化,实时增加新房间
				// console.log(v)
				this.ArenaArr.unshift(v)
			},

			//监听选中数组变化，计算总价值
			choiceList(Arr) {
				let Price = 0
				for (let i = 0; i < Arr.length; i++) {
					Price += Number(Arr[i].game_bean)
				}
				this.TotalPrice = Price
			}
		},

		components: {
			boxmod,
			BattleItem,
			[Notify.Component.name]: Notify.Component,
		}
	}
</script>

<style lang="scss" >

	.box{
		width: 100%;
		display: flex;
		flex-wrap: nowrap;
		color: white;
		height: 1rem;
		line-height: 1rem;
		justify-content: space-around;
		div{
			width: 2rem;
			text-align: center;
		}
		.active{
			color: #f9d240;
		}
	}
	.phb-fied{
		position: fixed;
		top: 0;
		left: 0;
		width: 330px;
		height: 100%;
		background-color: rgba(0,0,0,.5);
		padding: 10px 10px;
		z-index: 99999;
	}
	.phb{
		position: fixed;
		top: 50%;
		left: 0;
		width: 40px;
		& img{
			width: 100%;
			margin-right: 0px;
		}
	}
	.VsTop{
		background: url(../../assets/images/Vs/tops.png) no-repeat center;
		background-size: 100% 100%;
		width: 100%;
		height: 5.8rem;
		position: relative;
		img{
			border-radius: 50%;
			width: 2.45rem;
			height: 2.45rem;
			position: absolute;
			left: 3.48rem;
			top: 1.45rem;
		}
		h3{
			color: rgb(0, 0, 0);
			position: absolute;
			font-weight: 700;
			left: 4.6rem;
			top: 4rem;
		}
		p{
			color: rgb(0, 0, 0);
			position: absolute;
			font-weight: 700;
			left: 3.8rem;
			top: 4.9rem;
		}
	}
	.VSbox_list {
		width:100%;
		margin-top: 0.4rem;
		background-size: 100% 100%;
		height: 100%;
		overflow: hidden;
		img{
			margin-right: -6px;
		}
		.vslist_tit {
			width: 100%;
			text-align: center;
			padding: 0.1rem 0;
			p {
				font-size: 0.22rem;
				color: #fff;
				margin-bottom: 0.05rem;
			}
			span {
				font-size: 0.16rem;
			}
		}
		.Vs-right-bg {
			border: 1px solid yellow;
			border-top: 0;
			margin-top: -5px;
			.ranking-list{
				padding-bottom: 0.8rem;
				margin-top: 0.16rem;
				border-radius: 0.1rem;
				color: #c3c3e2;
				background: rgb(10, 18, 27);
				background-size: 100% 100%;
				text-align: center;
				min-height: auto;
			}
			.ranking-top{
				margin-top: 0.2rem;
				width: 100%;
				height: 100%;
			}
			.ranking-list-items{
				width: 100%;
			}
			.ranking-list-item{
				overflow: hidden;
				width: 100%;
				padding: 0.1rem 0.1rem;
				position: relative;
				display: flex;
				align-items: center;
				font-size: .14rem;
			}
			.rake-1{
				width:40px;
				font-size: .16rem;
				margin-right: 0.16rem;
				color: #fff;
				height: auto;
				background-image: url("../../assets/images/Vs/1.png");
				background-size: 100% 100%;
			}
			.rake-2{
				width:40px;
				font-size: .16rem;
				margin-right: 0.16rem;
				color: #fff;
				height: auto;
				background-image: url("../../assets/images/Vs/2.png");
				background-size: 100% 100%;

			}
			.rake-3{
				width:40px;
				font-size: .16rem;
				margin-right: 0.16rem;
				color: #fff;
				height: auto;
				background-image: url("../../assets/images/Vs/3.png");
				background-size: 100% 100%;

			}
			.rake{
				width:40px;
				font-size: .6rem;
				margin-right: 0.16rem;
				color: #fff;
				height: auto;
			}

			.list-items-name{
				width: 30%;
				font-size: .4rem;
				white-space: nowrap; 
				overflow: hidden;
				text-overflow: ellipsis;

			}
			.list-items-jf{
				width: 30%;
				text-align: left;
				font-size: .4rem;
			}
			.list-items-user{
				width: 40px;
				height: 40px;
				border-radius: 50%;
				overflow: hidden;
				& img{
					width: 100%;
					height: 100%;
				}
			}
		}
		.vsbox_item {
			margin-left: 6px;
			.active{
				background: rgb(245, 164, 17)!important;
				color: #1d003a!important;
				text-shadow: -2px 0 0 rgb(212, 223, 16) !important;
			}
			.vsbox_item_jf{
				color: #fff;
				cursor: pointer;
				flex-shrink: 0;
				width: 40%;
				height: 100%;
				background: #333;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: all .3s;
				padding: 5%;
				/*-webkit-clip-path: polygon(90% 0,100% 100%,0 100%,0 0);*/
				/*clip-path: polygon(90% 0,100% 100%,0 100%,0 0);*/
			}
			.vsbox_item_SL{
				color: #fff;
				cursor: pointer;
				position: relative;
				flex-shrink: 0;
				width: 40%;
				height: 100%;
				background: #333;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: all .3s;
				left: -4.5%;
				padding: 5%;
				-webkit-clip-path: polygon(100% 0,100% 100%,10% 100%,0 0);
				clip-path: polygon(100% 0,100% 100%,10% 100%,0 0);
			}
			width: 100%;
			box-sizing: border-box;
			overflow: hidden;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;






			.vsbox_pic {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 12px;
				img {
					max-width: 40px;
					max-height: 40px;
					vertical-align: middle;
				}
			}
			.vsbox_data {
				width: 150px;

				p {
					font-size: 16px;
					font-weight: bold;
					color: #75cd66;
					img {
						width: 18px;
						height: 18px;
						vertical-align: middle;
						margin-bottom: 2px;
						margin-right: 2px;
					}
				}
				span {
					font-size: 14px;
				}
			}
		}
		.top1 {
			background-size: 100% 100%;
			text-align: center;
			&-bg {
				margin: 0 auto;
				width: 80px;
				height: 80px;
				background-size: 100% 100%;
				background-image: url("../../assets/images/Vs/no1.png");
			}
			& div {
				font-size: 12px;
			}
			& img {
				width: 63%;
				height: 67%;
				margin-left: 1%;
				border-radius: 50%;
				margin-top: 13px;
			}
		}
		.top2 {
			display: flex;
			justify-content: space-around;
		}
		.paiming {
			padding: 60px 50px;
			background-image: url("../../assets/images/Vs/Group163@2x.png");
			background-size: 100% 100%;
		}
	}
	#Battle {
		padding: 10px;
		box-sizing: border-box;
		overflow: hidden;
		// width: 350px;

		.list-item {
			display: inline-block;
			transform: translateX(0);
		}

		.list-enter-active,
		.list-leave-active {
			transition: all .8s;
		}

		.list-enter,
		.list-leave-to

		/* .list-leave-active for below version 2.1.8 */
			{
			transform: translateX(100px);
			opacity: 0;
		}

		.Battle_nav {
			width: 100%;
			margin: 16px 0;
			.van-button__content{
				height: auto !important;
			}
			.van-button {
				margin-right: 10px;
				color: white !important;
				background: rgba(0, 0, 0, 0);
				border: 0px solid rgb(245, 164, 17);
				border-radius: 0;
				font-weight: 900;
				background: url('../../assets/images/985/pk/2.png') no-repeat center !important;
				background-size: 100% 100% !important;
				padding: 26px;
			}
			.van_button_info{
				// background: rgb(245, 164, 17) !important;
				background: url('../../assets/images/985/pk/2.png') no-repeat center !important;
				background-size: 100% 100% !important;
				color: white !important;
			}
		}
		
		.Battle_list {
			width: 100%;
			padding-top: 5px;
		}

		.van-popup {
			background: rgba($color: #000000, $alpha: 0.8);
			background-image: url(../../assets/images/af-1/bacs.jpg);
			background-size: cover;
			padding: 0px 20px;
			box-sizing: border-box;
			// width: 10rem !important;
			.pattern {
				width: 100%;
				height: 100px;
				display: flex;
				justify-content: space-around;
				margin-bottom: 10px;
				margin: 0 auto;
				align-items: center;
				background: url('../../assets/images/q2/addpk/topbtn.png') no-repeat center;
				background-size: 100% 100%;
				p {
					width: 100px;
					height: 50px;
					background: #0c1a03;
					color: #fff;
					line-height: 50px;
					text-align: center;
					font-size: 14px;
					border-radius: 4px;
					background: url('../../assets/images/q2/shop/nobutton.png') no-repeat center !important;
						background-size: 100% 100% !important;
					&.active {
						background: rgb(190, 205, 50);
						background: url('../../assets/images/q2/shop/button.png') no-repeat center !important;
						background-size: 100% 100% !important;
						color: black;
					}
				}
			}

			.choose {
				width: 100%;
				height: 40px;
				display: flex;
				align-items: center;
				color: #fff;
				font-size: 16px;
				margin: 5px 0;

				span {
					display: flex;
					align-items: center;
					margin-left: 5px;
					color: #75cd66;

					img {
						width: 18px;
						height: 18px;
						vertical-align: middle;
						margin-right: 1px;
					}
				}

			}

			.blind {
				width: 100%;
				// background: rgba($color: #fff, $alpha: .1);
				border-radius: 4px;
				padding: 5px;
				box-sizing: border-box;

				.blind_tit {
					font-size: 16px;
					color: #fff;
					margin-top: 5px;
				}

				.blind_list {
					width: 100%;
					height: 9rem;
					margin-top: 10px;
					display: flex;
					flex-wrap: wrap;
					overflow-y: auto;
					border-bottom: 4px solid rgba($color: #fff, $alpha: .01);
					box-sizing: border-box;
					justify-content: flex-start;
					// padding-left:0.1rem;
					.blind_item {
						width: 98px;
						height: 120px;
						border-radius: 4px;
						background: rgba($color: #000000, $alpha: .6);
						margin-right: 1px;
						margin-bottom: 7px;
						text-align: center;
						border: 1px solid #cccccc33;
						box-sizing: border-box;
						background: #16161677;
						background: url('../../assets/images/q2/addpk/list.png') no-repeat center;
						background-size: 100% 100%;
						.Repair {
							width: 100%;
							height: 20px;
							display: flex;
							justify-content: space-between;
							align-items: center;
							// padding: 0 10px;
							padding-right: 3px;
							box-sizing: border-box;

							.less {
								width: 18px;
								height: 18px;
								line-height: 18px;

								img {
									width: 14px;
									vertical-align: middle;
								}
							}

							.What {
								display: flex;
								align-items: center;
								color: #fff;
								font-size: 14px;
							}
						}

						.blind_pic {
							width: 60px;
							height: 60px;
							display: flex;
							justify-content: center;
							align-items: center;
							margin: 0 auto;
							img {
								vertical-align: middle;
								margin: 100%;
								max-height: 100%;
							}
						}

						span {
							font-size: 10px;
							color: #75cd66;

							img {
								width: 12px;
								height: 12px;
								vertical-align: middle;
								margin-bottom: 2px;
							}
						}

						p {
							text-align: center;
							color: #fff;
							font-size: 10px;
						}
					}
				}
			}

			.batch {
				width: 100%;
				// background: rgba($color: #fff, $alpha: .1);
				border-radius: 4px;
				margin-top: 10px;
				padding: 10px 5px;
				box-sizing: border-box;
				display: flex;
				align-items: center;

				.batch_tit {
					font-size: 16px;
					color: #fff;
					margin-right: 10px;
				}

				.van-radio-group {
					.van-radio--horizontal {
						span {
							color: #fff;
							font-size: 14px;
							margin-left: 5px;
						}

						&:last-child {
							margin-right: 0;
						}
					}


				}
			}

			.establish_btns {
				width: 100%;
				height: 50px;
				text-align: center;
				line-height: 50px;
				padding-bottom: 100px;
				.van-button {
					font-size: 16px;
					margin: 0 10px;
					padding: 22px;
					// padding-right: 18px;
					color: black !important;
					background: url('../../assets/images/q2/shop/button.png') no-repeat center !important;
					background-size: 100% 100% !important;
					
				}
			}
		}
	}
</style>
